body {
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: Apercu, Proxima Nova W01, Helvetica Neue, Calibri, Roboto,
    sans-serif;
  font-size: 16px;
  height: 100vh;
}
